import React from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";

function SEOAdditional({ titleTag, title, content, className }) {
    const HeadingTag = titleTag;
    const HeadingClass = HeadingTag == 'h2' ? 'text-lg' : 'mb-4';

    return (
        <div className={`w-full max-w-6xl mx-auto text-gray-500 dark:text-gray-400 ${className}`}>
            <HeadingTag className={`font-semibold ${HeadingClass}`}>{title}</HeadingTag>
            <div className="text-sm space-y-5">
                {parse(content)}
            </div>
        </div>
    );
}

SEOAdditional.propTypes = {
    titleTag: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    className: PropTypes.string
};

export default SEOAdditional;

import React, { useState, useContext } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SEOAdditional from "../components/seo-additional";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Button from "../components/button";

import parse from "html-react-parser";

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup,
    Dot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";

import styled from "styled-components";
const Hero = styled.section`
    .bg-shipping {
        background-image: linear-gradient(
                90deg,
                #fff 20%,
                rgba(216, 216, 216, 0) 80%
            ),
            url(../images/women-shipping.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 200px 40%;
    }

    .bg-shipping-dark {
        background-image: linear-gradient(
                90deg,
                #202a37 20%,
                rgba(216, 216, 216, 0) 80%
            ),
            url(../images/women-shipping.webp);
    }
`;

import { ThemeContext } from "../context/themeContext";

import { UilAngleDown } from "@iconscout/react-unicons";

function Icon({ icon }) {
    let uil = {
        chevronDown: UilAngleDown
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

function ShippingManagement({ data }) {
    let d = data.contentfulPageShipping;
    const [itemsToShow, setItemsToShow] = useState(1);

    const { theme } = useContext(ThemeContext);

    return (
        <Layout>
            <SEO
                keywords={d.seo.keywords}
                title={d.seo.title}
                description={d.seo.description}
            />

            <Hero>
                <div
                    id="hero"
                    className={`bg-shipping ${
                        theme === "dark" ? `bg-shipping-dark` : null
                    } flex-none md:flex w-full mx-auto`}
                >
                    <div className=" mx-auto max-w-6xl px-4 py-8 md:pt-24 md:pb-24 text-left h-auto">
                        <div className="w-full md:w-1/2">
                            <span className="uppercase tracking-widest text-xs text-gray-500">
                                {d.label}
                            </span>

                            <h1 className="text-2xl xs:text-3xl sm:text-4xl md:text-5xl mb-8 font-display breakline text-primary dark:text-gray-200">
                                {d.content.head.description}
                            </h1>

                            <p className="text-lg sm:text-xl mt-10">
                                {d.content.body_1.description}
                            </p>

                            <div className="mt-10">
                                <Button
                                    key={d.content.cta.title}
                                    text={d.content.cta.title}
                                    size="xl"
                                    color="primary"
                                    url={d.content.cta.url}
                                    className="cta_hero"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Hero>

            <section
                id="couriers"
                className="max-w-6xl mx-auto py-10 px-4 md:px-0"
            >
                <div className="rounded-xl p-6 shadow-lg border border-gray-200 dark:border-gray-800 dark:bg-gray-700">
                    <h4 className="text-xl mb-6 md:mb-10 text-center leading-tight font-display">
                        {d.content.head.expedition_title}
                    </h4>

                    <div className="flex flex-wrap justify-around items-center">
                        {d.content.head.expedition.map((item) => (
                            <img
                                key={item}
                                src={item.url_logo}
                                className="content-center flex mx-4 mb-6"
                                style={{
                                    width: `auto`,
                                    height: `auto`,
                                    maxWidth: `100px`,
                                    maxHeight: `35px`,
                                }}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section
                id="benefit"
                className="max-w-6xl mx-auto py-16 px-4 md:px-0"
            >
                <h4 className="text-3xl md:text-4xl mb-6 md:mb-10 leading-tight font-display text-primary dark:text-gray-200">
                    {d.content.body_1.title}
                </h4>

                <div className="flex flex-wrap w-full">
                    {d.content.body_1.content.map((item, i) => (
                        <div
                            className="flex w-full md:w-1/2 py-5 md:py-8 pr-4 md:pr-8 items-center"
                            key={i}
                        >
                            <div className="w-1/5 mb-2 mr-4">
                                <img
                                    className="w-full h-auto p-4"
                                    src={`../icons/shipping-benefit-${
                                        i + 1
                                    }.svg`}
                                    alt=""
                                />
                            </div>

                            <div className="w-4/5">
                                <h2 className="text-2xl font-sans leading-none mb-4">
                                    {item.title}
                                </h2>
                                <p className="text-gray-500 dark:text-gray-400">
                                    {item.desc}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section id="table" className="bg-gray-100 dark:bg-gray-800">
                <div className="max-w-6xl mx-auto py-16 px-4 md:px-0">
                    <div className="max-w-4xl mx-auto text-center">
                        <h4 className="text-3xl md:text-4xl mb-2 md:mb-4 leading-tight font-display">
                            {parse(d.content.body_2.title)}
                        </h4>

                        <p className="text-lg mb-6 md:mb-10">
                            {parse(d.content.body_2.subtitle)}
                        </p>
                    </div>

                    <div className="w-full h-auto p-6 rounded-xl shadow-lg bg-white dark:bg-gray-700 overflow-x-scroll">
                        <table className="table-auto font-body w-full">
                            <thead>
                                <tr>
                                    <th className="text-left th-border-bottom px-3 py-6"></th>
                                    {d.content.body_2.content.map((item) => (
                                        <th
                                            key={item.name}
                                            className="w-auto text-left th-border-bottom px-3 py-6"
                                            style={{
                                                width: `25%`,
                                                minWidth: `120px`,
                                            }}
                                        >
                                            <img
                                                src={item.url_logo}
                                                className="content-center flex mx-auto"
                                                style={{
                                                    width: `auto`,
                                                    height: `auto`,
                                                    maxWidth: `80px`,
                                                    maxHeight: `25px`,
                                                }}
                                            />
                                        </th>
                                    ))}
                                </tr>

                                {d.content.body_2.side_column.map((item, i) => (
                                    <tr key={i}>
                                        <td className="text-left td-border-bottom px-3 py-6">
                                            <p className="font-display leading-tight">
                                                {item.text}
                                            </p>
                                        </td>

                                        {d.content.body_2.content.map(
                                            (item, x) => (
                                                <td
                                                    className="text-center leading-tight td-border-bottom px-3 py-6"
                                                    key={x}
                                                >
                                                    {i === 0 ? (
                                                        <span className="text-xl font-bold">
                                                            {
                                                                item.service
                                                                    .cashback
                                                            }
                                                        </span>
                                                    ) : null}
                                                    {i === 1
                                                        ? item.service.rate
                                                        : null}
                                                    {i === 2 ? (
                                                        item.service.cod ===
                                                        true ? (
                                                            <img
                                                                src="../icons/check.svg"
                                                                className="flex mx-auto justify-center w-4 h-4"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="../icons/cross.svg"
                                                                className="flex mx-auto justify-center w-4 h-4"
                                                            />
                                                        )
                                                    ) : null}
                                                    {i === 3
                                                        ? item.service
                                                              .return_fee
                                                        : null}
                                                    {i === 4
                                                        ? item.service
                                                              .additional_fee
                                                        : null}
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))}
                            </thead>
                        </table>
                    </div>
                </div>
            </section>

            <section
                id="steps"
                className="px-4 md:px-0 bg-primary dark:bg-gray-700"
            >
                <div className="max-w-6xl mx-auto py-24">
                    <h4 className="text-3xl md:text-4xl mb-8 md:mb-10 leading-tight font-display text-blue-50 text-center">
                        {d.content.body_3.title}
                    </h4>

                    <div className="flex-none md:flex md:space-x-8">
                        {d.content.body_3.content.map((item, i) => (
                            <div
                                key={i}
                                className="md:flex-1 border dark:border-gray-800 bg-blue-50 dark:bg-gray-800 rounded-xl p-8 mb-6 md:mb-0 relative shadow-xl"
                            >
                                <img
                                    src={`../icons/shipping-${i + 1}.svg`}
                                    className="flex mx-auto justify-center h-32 mb-6"
                                />

                                <div className="text-white dark:text-gray-200 rounded-full w-8 h-8 flex items-center justify-center leading-none font-bold font-display absolute left-2 top-2 bg-primary dark:bg-gray-700">
                                    {i + 1}
                                </div>
                                <p className="md:text-lg font-sans leading-normal text-primary dark:text-gray-200">
                                    {item}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section
                id="testimonial"
                className="px-4 md:px-0 py-16 dark:bg-gray-800"
            >
                <CarouselProvider
                    isIntrinsicHeight={true}
                    totalSlides={3}
                    step={1}
                    className="max-w-3xl mx-auto relative"
                >
                    <div className="absolute left-0 top-0 text-blue-50 dark:text-gray-200 -z-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            height="100"
                            className="fill-current"
                            viewBox="0 0 16 16"
                        >
                            <path d="M9 9V2h7v7.1c0 4.8-4.5 5.4-4.5 5.4l-.6-1.4s2-.3 2.4-1.9c.4-1.2-.4-2.2-.4-2.2H9zM0 9V2h7v7.1c0 4.8-4.5 5.4-4.5 5.4l-.6-1.4s2-.3 2.4-1.9C4.7 10 3.9 9 3.9 9H0z" />
                        </svg>
                    </div>
                    <Slider>
                        {d.content.body_4.content.map((item, i) => (
                            <Slide
                                index={item}
                                key={`slider-${i}`}
                                className="flex h-auto w-full p-10"
                            >
                                <div className="flex flex-col items-center h-auto w-full">
                                    <p className="text-3xl md:text-4xl font-extralight leading-tight mb-8 font-sans text-primary dark:text-gray-200">
                                        &ldquo;{item.text}&rdquo;
                                    </p>

                                    <div className="flex flex-col w-full">
                                        <img
                                            src={`../images/shipping-testi-${
                                                i + 1
                                            }.png`}
                                            alt=""
                                            className="mb-4"
                                            style={{
                                                width: `auto`,
                                                height: `auto`,
                                                maxWidth: `180px`,
                                                maxHeight: `100px`,
                                            }}
                                        />

                                        <p>
                                            <span className="font-display text-lg">
                                                {item.name},
                                            </span>
                                            <span className="text-md text-gray-500 dark:text-gray-400">
                                                &nbsp;{item.shop}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </Slide>
                        ))}
                    </Slider>
                    <div className="flex w-full">
                        <DotGroup className="mx-auto">
                            <Dot
                                className="w-2 h-2 rounded-full mx-2 bg-gray-700 focus:outline-none opacity-25"
                                slide={0}
                            ></Dot>
                            <Dot
                                className="w-2 h-2 rounded-full mx-2 bg-gray-700 focus:outline-none opacity-25"
                                slide={1}
                            ></Dot>
                            <Dot
                                className="w-2 h-2 rounded-full mx-2 bg-gray-700 focus:outline-none opacity-25"
                                slide={2}
                            ></Dot>
                        </DotGroup>
                    </div>
                </CarouselProvider>
            </section>

            <section
                id="faq"
                className="px-4 md:px-0 bg-gray-50 dark:bg-gray-800"
            >
                <div className="max-w-3xl mx-auto py-16">
                    <h4 className="text-3xl md:text-4xl mb-8 md:mb-10 leading-tight font-display text-center">
                        {d.content.faq.title}
                    </h4>

                    <Accordion>
                        {d.content.faq.content.map((item, i) => (
                            <AccordionItem
                                key={i}
                                className="p-4 border border-gray-100 dark:border-gray-800 shadow-lg rounded-lg mb-6 bg-white dark:bg-gray-700"
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton className="focus:outline-none">
                                        <h5 className="text-xl font-sans leading-tight">
                                            {parse(item.title)}
                                        </h5>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className="mt-3 text-gray-500 dark:text-gray-400">
                                        {parse(item.desc)}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>

            <section
                id="cta"
                className="py-24 px-4 md:px-0 bg-secondary dark:bg-gray-800"
            >
                <div className="max-w-6xl mx-auto">
                    <h4 className="text-3xl md:text-4xl mb-6 md:mb-10 leading-tight font-display text-center">
                        {d.content.cta.desc}
                    </h4>

                    <div className="flex justify-center">
                        <Button
                            key={d.content.cta.title}
                            text={d.content.cta.title}
                            size="xl"
                            color="primary"
                            url={d.content.cta.url}
                            className="cta_bottom"
                        />
                    </div>
                </div>
            </section>

            {d.seoAdditional.length ?
                <section className="pt-8 md:pt-12 px-4 bg-gray-50 dark:bg-gray-800 space-y-5">
                    {d.seoAdditional.map((seoAdd, key) => (
                        <SEOAdditional
                            key={`seo-additional-${key}`}
                            titleTag={seoAdd.titleTag}
                            title={seoAdd.title}
                            content={seoAdd.content}
                            className={key >= itemsToShow ? 'hidden' : ''}
                        />
                    ))}

                    {itemsToShow == 1 ? 
                        <div className="w-full max-w-6xl mx-auto">
                            <button
                                type="button"
                                className="flex flex-row text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 hover:underline border-b border-dashed"
                                onClick={() => setItemsToShow(d.seoAdditional.length)}
                            >
                                <span>Selengkapnya</span><Icon icon={'chevronDown'} />
                            </button>
                        </div>
                        : ''
                    }
                </section>
                : ''
            }
        </Layout>
    );
}

Icon.propTypes = {
    icon: PropTypes.string,
};

ShippingManagement.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageShipping {
            label
            content {
                head {
                    description
                    expedition {
                        name
                        url_logo
                    }
                    expedition_title
                    title
                }
                body_1 {
                    content {
                        icon
                        title
                        desc
                    }
                    description
                    title
                }
                body_2 {
                    title
                    subtitle
                    side_column {
                        name
                        text
                    }
                    content {
                        name
                        url_logo
                        service {
                            cashback
                            rate
                            cod
                            return_fee
                            additional_fee
                        }
                    }
                }
                body_3 {
                    content
                    title
                }
                body_4 {
                    content {
                        name
                        shop
                        text
                        title
                    }
                    title
                }
                faq {
                    title
                    content {
                        title
                        desc
                    }
                }
                cta {
                    title
                    desc
                    url
                }
            }
            seo {
                title
                description
                keywords
            }
            seoAdditional {
                titleTag
                title
                content
            }
        }
    }
`;

export default ShippingManagement;
